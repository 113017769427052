import {useTranslations} from '@/hooks/useTranslations';
import useHandle from '@/hooks/useHandle';
import type {GlobalNavigation} from '@/types';

export default function useGlobalNav(): GlobalNavigation {
  const options = useTranslations();
  const {t, site} = options;
  // Allows pages to set any propery on globalNav
  const {globalNav} = useHandle();
  const pathPrefix = site?.locale !== 'en' ? `/${site?.pathPrefix}` : '';

  const nav = globalNav ? globalNav(options) : {};

  // The homeUrl is https://undefined when this is called from
  // within ErrorBoundary. This is a workaround.
  let homeUrl = nav.homeUrl || t('global:globalNav.homeUrl');
  if (!homeUrl || homeUrl === 'https://undefined') {
    homeUrl = '/';
  }

  const headerPdfCta = t('global:globalNav.headerPdfCta');
  const headerPdfCtaLink = t('global:globalNav.headerPdfCtaLink');
  const loginUrl =
    nav.loginUrl ||
    `${pathPrefix}${t('global:globalNav.loginUrl')}?ui_locales=${site?.locale}`;
  const loginText = nav.loginText || t('global:globalNav.loginText');
  const signupText = nav.signupText || t('global:globalNav.signupText');
  const searchText = t('global:globalNav.searchText');
  const searchPlaceholder = t('global:globalNav.searchPlaceholder');
  const searchPlaceholderMobile = t('global:globalNav.searchPlaceholderMobile');
  const signupTextPlain =
    nav.signupTextPlain || t('global:globalNav.signupTextPlain');
  return {
    headerPdfCta,
    headerPdfCtaLink,
    homeUrl,
    loginUrl,
    loginText,
    signupText,
    signupTextPlain,
    searchText,
    searchPlaceholder,
    searchPlaceholderMobile,
  };
}
